<template>
  <div>
    <b-card>
      <b-row class="body-info" v-if="clientPrepare">
        <b-col md="2" sm="12">
          <img
            v-if="client.image"
            :src="client.image.path"
            class="w-75"
            :alt="client.name"
          />
        </b-col>
        <b-col md="10" sm="12">
          <b-row>
            <b-col md="12">
              <div>
                <h2 class="ficha-info_name mb-50 mt-50">
                  {{ client.name }}
                </h2>
              </div>
            </b-col>
            <b-col md="4" sm="12" v-if="client.cif">
              <p>
                <strong>{{ $t("Cif") }}:</strong>
                <small>{{ client.cif }}</small>
              </p>
            </b-col>
            <b-col md="12" sm="12" v-if="client.address">
              <p>
                <strong>{{ $t("Direccion") }}:</strong>
                <small
                  >{{ client.address }}, {{ client.postalCode }}
                  {{ client.city }} {{ client.region }},
                  {{ client.country }}</small
                >
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4" sm="12" v-if="client.email">
              <p>
                <strong>{{ $t("Email") }}:</strong>
                <small>{{ client.email }}</small>
              </p>
            </b-col>
            <b-col md="4" sm="12" v-if="client.phone">
              <p>
                <strong>{{ $t("Telefono") }}:</strong>
                <small>{{ client.phone }}</small>
              </p>
            </b-col>
            <b-col md="4" sm="12" v-if="client.web">
              <p>
                <strong>{{ $t("Web") }}:</strong>
                <small>{{ client.web }}</small>
              </p>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <span class="text-right float-right">
                <b-link
                  :to="{ name: 'editClient', params: { id: client.id } }"
                  class="d-inline-block text-dark"
                >
                  <span class="">{{ $t("Editar") }}</span>
                  <span class="pl-1"><feather-icon icon="Edit2Icon" /></span>
                </b-link> </span
            ></b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import { BCard, BRow, BCol, BLink } from "bootstrap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  emits: ["onload"],
  components: {
    BCard,
    BLink,
    BRow,
    BCol,
  },
  props: {
    clientprop: {
      type: String || Number,
    },
  },
  data() {
    return {
      clientPrepare: false,
    };
  },
  computed: {
    ...mapGetters({
      client: "clients/getClient",
      currentClient: "clients/getCurrentClient",
      currentRole: "auth/getRole",
    }),
    imgClient() {
      if (this.client.image) {
        return this.client.image.path;
      }
      return "";
    },
  },
  methods: {
    ...mapActions({
      getClient: "clients/getClient",
    }),
  },
  async created() {
    let id = this.clientprop ? this.clientprop : this.currentClient.id;
    await this.getClient(id);
    await this.$emit("onload", true);
    this.clientPrepare = true;
  },
};
</script>
