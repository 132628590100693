<template>
  <div>
    <b-row>
      <b-col cols="12">
        <!-- info -->
        <CardClient :clientprop="$route.params.id" @onload="chargeAssets" />
      </b-col>
      <b-col cols="12">
        <ListAssets :clientprop="$route.params.id" v-if="charge"/>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import ListAssets from '@/views/installation/ListTable.vue'
import CardClient from '@/components/clients/CardClient.vue'

export default {
  data() {
    return {
      charge: false,
    }
  },
  components: {
    CardClient,
    BRow,
    BCol,
    ListAssets,
  },
  methods: {
    chargeAssets(value) {
      this.charge = value
    },
  },
}

</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
